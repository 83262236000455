export default [
  {
    prop: 'id',
    label: 'ID',
    minWidth: 30
  },
  {
    prop: 'name',
    label: 'Nome',
    minWidth: 80
  },
  {
    prop: 'email',
    label: 'Email',
    minWidth: 50
  },
  {
    prop: 'city',
    label: 'Cidade',
    minWidth: 40
  },
  {
    prop: 'province',
    label: 'Estado',
    minWidth: 40
  },
  {
    prop: 'role_label',
    label: 'Função',
    minWidth: 40
  },
  {
    prop: 'status_name',
    label: 'Status',
    minWidth: 30
  }
]
