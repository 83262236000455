<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <h4 slot="header" class="card-title">{{ currentTable }}</h4>
          <div class="row">
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <el-select class="select-primary pagination-select" v-model="pagination.perPage" placeholder="Por página">
                <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item"></el-option>
              </el-select>

              <div class="d-flex align-items-center">
                <el-button v-if="role == 'prefeitura'" class="btn new-btn mr-3" @click="$router.push({path: '/usuario'})">Cadastrar Novo</el-button>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Procurar item" v-model="searchQuery" aria-controls="datatables"></el-input>
              </div>
            </div>
            <div class="col-12">
              <el-table :data="tableData" v-if="pagination.total">
                <el-table-column :min-width="20" align="left" label="Foto">
                  <template v-slot="props" class="photo" style="height: 40px;width: 40px;margin-left: 0;">
                    <img :src="props.row.avatar_url" alt="" />
                  </template>
                </el-table-column>

                <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth" :prop="column.prop" :label="column.label"></el-table-column>

                <el-table-column :min-width="60" v-if="pagination.total" align="right" label="Ações">
                  <template v-slot="props">
                    <el-button type="success" icon="tim-icons icon-single-02" plain circle @click="openUser(props.row)" size="small"></el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div slot="footer" class="col-12 mt-3 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <p class="card-category table-footer" v-if="pagination.total">
                Exibindo de {{ from }} à {{ to }} de
                {{ pagination.total }} registros
              </p>
              <p class="card-category table-footer" v-else>
                Nenhum registro encontrado.
              </p>
              <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage" :total="pagination.total" v-if="pagination.total"></base-pagination>
            </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import { Table, TableColumn, Select, Option, Button } from 'element-ui'
import { BasePagination } from '@/components'
import tableColumns from './tableColumns'

const defaultPagination = {
  perPage: 10,
  currentPage: 1,
  perPageOptions: [10, 25, 50, 100],
  total: 0
}

export default {
  data () {
    return {
      tableData: [],
      status: 0,
      search: '',
      pagination: { ...defaultPagination },
      tableColumns: tableColumns
    }
  },
  methods: {
    async getData (reset = false) {
      if (reset) this.pagination.currentPage = 1
      const { role, pagination, search } = this
      const page = pagination.currentPage
      const perPage = pagination.perPage
      const url = `admin/users?role=${role}&page=${page}&limit=${perPage}&search=${search}`
      const { data } = await this.$http.get(url)
      this.tableData = data.data
      this.pagination.total = data.total
    },
    openUser ({ id }) {
      this.$router.push(`/usuario/${id}`)
    }
  },
  mounted () {
    this.getData()
  },
  watch: {
    role () {
      this.getData(true)
    },
    search: _.debounce(function () {
      this.getData(true)
    }, 350),
    'pagination.currentPage' () {
      this.getData()
    },
    'pagination.perPage' () {
      this.getData(true)
    }
  },
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button
  },
  computed: {
    role () {
      return this.$route.params.role
    },

    to () {
      const { currentPage, perPage } = this.pagination
      const calc = currentPage * perPage
      if (this.from + this.tableData.length - 1 < calc) {
        return this.tableData.length
      }
      return calc
    },

    from () {
      const { currentPage, perPage } = this.pagination
      return currentPage * perPage - (perPage - 1)
    },

    currentTable () {
      let labelRole = ''
      switch (this.role) {
        case 'company-master':
          labelRole = 'Empresa coletora'
          break
        case 'coletor-funcionario':
          labelRole = 'Agente de coleta'
          break
        case 'coletor-point':
          labelRole = 'Ponto de coleta'
          break
        case 'prefeitura':
          labelRole = 'Prefeituras'
          break
        default:
          break
      }
      const msg = `lista de ${this.$route.name} - ${labelRole}`
      return msg.toUpperCase()
    }
  }
}
</script>
<style>
.pagination-select,
.search-input {
  width: 200px;
}

p.table-footer {
  text-transform: none;
}

.btn-new {
  height: 2.5rem;
}
</style>
